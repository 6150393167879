import React from 'react'

export default function Footer() {
  return (
    <footer className="footer" id="footer">
    <div className="container">
      <div className="row">
        <div className="col-md-3 col-sm-6">
          <h4>Информация</h4>
          <ul className="list-unstyled">
            <li><router-link className="nav-link" to="/">Главная</router-link></li>
          </ul>
        </div>
        <div className="col-md-3 col-sm-6">
          <h4>Часы работы</h4>
          <ul className="list-unstyled">
            <li>Казань, Максимова 27А</li>
            <li>пн - сб: с 8:00 до 22:00</li>
            <li>вс: с 8:00 до 21:00</li>
          </ul>
        </div>
        <div className="col-md-3 col-sm-6">
          <h4>Контакты</h4>
          <ul className="list-unstyled">
            <li><a href="tel:+78435107504" className="ms-2 ">+7 (843) 510‒75‒04</a></li>
            <li>пн - сб: с 8:00 до 22:00</li>
            <li>вс: с 8:00 до 21:00</li>
          </ul>
        </div>
        <div className="col-md-3 col-sm-6">
          <h4>Мы в соцсетях</h4>
          <ul className="footer-icons">
            <li><a href="https://vk.com/harija" target="_blank"><i className="px-1 fa-brands fa-vk"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
  )
}

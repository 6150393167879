import React from 'react'

function MiniCard(props) {
  const { id, price } = props

  return (
    <div className="product-card">
              <div className="product-card-offer">
                <div className="offer-hit">Hit</div>
                {/* <!--                <div className="offer-new">New</div>--> */}
              </div>
              <div className="product-thumb">
                {/* <router-link className="nav-link" :to="`/detail/${product.id}`">
                  <img :src="product.image_url" alt="">
                </router-link> */}
              </div>
              <div className="product-details">
                <h4>
                  {/* <router-link className="nav-link" :to="`/detail/${product.id}`">
                    {{ product.title }}
                  </router-link> */}
                </h4>
                <p className="product-excerpt">
                  product.description {id}
                </p>
                <div className="product-bottom-details d-flex justify-content-between">
                  <div className="product-price">
                    {/* <!--                    <small>700p.</small>--> */}
                    {price} р.
                  </div>
                  <div className="product-links">
{/* <!--                    <a href="#" className="btn btn-outline-secondary add-to-cart">-->
<!--                      <i className="fas fa-shopping-cart"></i>-->
<!--                    </a>--> */}
                  </div>
                </div>
              </div>
            </div>
  )
}
export default MiniCard


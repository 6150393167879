import React, { useEffect, useState } from 'react'
import Menu from '../components/Menu'
import img1 from '../assets/img/slider/1.jpg'
import img2 from '../assets/img/slider/2.jpg'
import img3 from '../assets/img/slider/3.jpg'
import MiniCard from '../components/MiniCard'
import axios from 'axios'


export default function Main() {
  const src = 'https://admin.kleversoft.ru/api/main';

  const [items, setItems] = useState([]);

  useEffect(() => {
    axios.get(src)
    .then(({data}) => {
       setItems(data.hits)
        console.log(data.hits);
      
    }).catch(err => {
      // console.log(err);
      // Alert.alert('Ошибка', 'Не удалось подключится к серверу');
    }).finally(() => {
      // setIsLoading(false);
    });
  }, []);



  return (
    <>
    <Menu/>
    <div className="container">
    
       <div id="carousel" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={img1} className="d-block w-100" alt="..."/>
            </div>
            <div className="carousel-item">
              <img src={img2} className="d-block w-100" alt="..."/>
            </div>
            <div className="carousel-item">
              <img src={img3} className="d-block w-100" alt="..."/>
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carousel" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Предыдущий</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carousel" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Следующий</span>
        </button>
        </div> 
      </div>

      <section className="advantages d-none d-lg-block">
      <div className="container">
        <div className="row mb-5">
          <div className="col-12">
            <h2 className="section-title">
              <span>Наши преимущества</span>
            </h2>
          </div>
        </div>


        <div className="row gy-3 items">
          <div className="col-lg-3 col-sm-6">
            <div className="item">
              <p>
                <i className="fas fa-shipping-fast"></i>
              </p>
              <p>Прямые поставки от производителей брендов</p>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="item">
              <p>
                <i className="fas fa-cubes"></i>
              </p>
              <p>Широкий ассортимент товаров. Более 10 тыс. наименований</p>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="item">
              <p>
                <i className="fas fa-hand-holding-usd"></i>
              </p>
              <p>Приятные и конкурентные цены</p>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="item">
              <p>
                <i className="fa-solid fa-user-graduate"></i>
              </p>
              <p>Консультации от профессионалов</p>
            </div>
          </div>
        </div>

      </div>
    </section>

    <section className="featured-product">
      <div className="container">
        <div className="row mb-5">
          <div className="col-12">
            <h2 className="section-title">
              <span>Рекомендуемые товары</span>
            </h2>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">

          {
            items.map((item) => 
              <div className="col-xl-3 col-lg-4 col-md-6 mb-3">
                <MiniCard id={item.id} price={item.price}/>
              </div>
              
            )
          }
        


        </div>
      </div>
    </section>






        
    </>
  )
}

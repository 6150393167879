import React from 'react'

function Menu() {
  return (
    <>
    <div className="header-bottom sticky-top" id="header-nav">
    <nav className="navbar navbar-expand-lg bg-primary" data-bs-theme="dark">
      <div className="container">

        <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-expanded="false"
                aria-label="Переключатель навигации">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasNavbar"
             aria-labelledby="offcanvasNavbarLabel">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasExampleLabel">Каталог</h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas"
                    aria-label="Закрыть"></button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a href='/' className="nav-link">Главная</a>
              </li>


                <div>

                  <a href='/' className="nav-link">
                     category.title 
                  </a>

                </div>


            </ul>
          </div>
        </div>
        <div className="cart-buttons d-block d-lg-none">

        </div>


      </div>
    </nav>
  </div>
    </>
  )
}

export default Menu
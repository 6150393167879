import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Footer from "./components/Footer.js";
import Header from "./components/Header.js";
import Main from "./views/Main.js";
import About from "./views/About.js";


function App() {
  return (
    <div className="wrapper">
      <Header/>
      <main className="main">
      <BrowserRouter>
        <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about" element={<About />} />
        </Routes>
    </BrowserRouter>

      </main>
      <Footer/>
    </div>
    
  );
}

export default App;

